import {
  GizmoHelper,
  GizmoViewport,
  OrbitControls,
  OrthographicCamera,
} from "@react-three/drei";
import React from "react";

const Camera = ({ camRef, gizmo = false }) => {
  return (
    <>
      <OrthographicCamera
        ref={camRef}
        makeDefault
        near={0}
        zoom={40} // TODO: set dynamically
        position={[30, 30, 30]}
      />
      <OrbitControls
        camera={camRef.current}
        enableDamping={true}
        dampingFactor={0.1}
      />
      {gizmo && (
        <GizmoHelper alignment="top-right" margin={[80, 80]}>
          <GizmoViewport
            axisColors={["red", "green", "blue"]}
            labelColor="black"
          />
        </GizmoHelper>
      )}
    </>
  );
};

export default Camera;
