import React, { useRef } from "react";
import { useFrame, useThree } from "react-three-fiber";
import BlockGrid from "./Content/BlockGrid";
import Camera from "./Scene/Camera";
import Lights from "./Scene/Lights";
import Skybox from "./Scene/Skybox";

const GameContent = ({ state }) => {
  const scene = useRef();

  const { camera } = useThree(); // with useFrame, allows multiple camera for HUDs
  useFrame(
    ({ gl }) =>
      void ((gl.autoClear = false),
      gl.clearDepth(),
      gl.render(scene.current, camera)),
    100 // render priority
  );
  // const camera = useResource() // alternate option, if no HUD

  return (
    <>
      <scene ref={scene}>
        <Camera camRef={camera} gizmo={true} />
        <Lights />
        <Skybox />
        <BlockGrid rows={20} cols={20} scale={1} />
      </scene>
    </>
  );
};

export default GameContent;
