import { Stars } from '@react-three/drei'
import React from 'react'

const Skybox = () => {
  return (
    <Stars
        radius={50} // Radius of the inner sphere (default=100)
        depth={20} // Depth of area where stars should fit (default=50)
        count={10000} // Amount of stars (default=5000)
        factor={5} // Size factor (default=4)
        saturation={0.3} // Saturation 0-1 (default=0)
        fade // Faded dots (default=false)
      />
  )
}

export default Skybox
