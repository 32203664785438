import React, { useState } from "react";
import "./debug.css";
import Readout from "./Readout";

const DebugInterface = ({ connect, disconnect }) => {
  const [connected, setConnected] = useState(false);
  const [readoutText, setReadoutText] = useState("Some shit from the server ");

  return (
    <div id="debug-interface">
      <p style={{ color: "yellow", margin: "0" }}>DEBUG MODE</p>
      {connected ? (
        <button
          className="clickable"
          onClick={() => {
            setConnected(false);
            disconnect();
          }}
        >
          Disconnect
        </button>
      ) : (
        <button
          className="clickable"
          onClick={() => {
            setConnected(true);
            connect("ws://localhost:8090");
          }}
        >
          Connect
        </button>
      )}
      <Readout text={readoutText} />
    </div>
  );
};

export default DebugInterface;
