import { proxy } from 'valtio';
// import UserInterface from './components/Interface/UserInterface';
import Game from './components/Game';

const canvasStyle = {
  // TODO: use a skybox instaed of gradient?
  background: `radial-gradient(
    circle farthest-corner at center center,
    #1D2021,
    #000000
  ) no-repeat`,
  position: "absolute",
  width: "100%",
  height: "100%"
}

// mutate gamestate, read using useProxy(gamestate)
const gamestate = proxy(
  {}
)

function App() {
  // Mouse raycaster returns all meshes it intersects,
  // This isolates the closest one.
  const closest = (intersects, state) => {
    // TODO: for obj in intersects, check obj.userData.captureRay 
    // console.log(intersects.slice(0, 1));
    return intersects.slice(0, 1); 
  }

  return (
    <Game state={gamestate} server="ws://localhost:3000" noconnect >
      
    </Game>
  );
}

export default App;
