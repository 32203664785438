import React, { useMemo, useRef, useState } from "react";
import { BoxBufferGeometry, Color } from "three";
// import { extend } from 'react-three-fiber';
// import { OutlineEffect } from 'three/examples/jsm/effects/OutlineEffect'

// extend({ OutlineEffect })

const COLOR_GRASS = new Color("#107812");
const COLOR_HOVER = new Color("#0461D1");

const Block = ({ size, ...props }) => {
  const mesh = useRef();
  const [hovered, setHovered] = useState(false);

  const boxgeom = useMemo(() => new BoxBufferGeometry(size, size, size), [
    size,
  ]);

  return (
    <mesh
      {...props}
      ref={mesh}
      onPointerOver={(event) => setHovered(true)}
      onPointerOut={(event) => setHovered(false)}
      geometry={boxgeom}
      userData={{ captureRay: true }}
    >
      <meshStandardMaterial color={hovered ? COLOR_HOVER : COLOR_GRASS} />
    </mesh>
  );
};

export default Block;
