import Block from "./Block";

const BlockGrid = ({ rows, cols, scale }) => {
  /* TODO: 
     stop using these offsets once I figure out how tf 
     to declaratively pan the camera...
  */
  const osX = cols / 2 - 1;
  const osY = rows / 2 - 1;

  const gridBlocks = () => {
    var blocks = [];
    for (var r = 0; r < rows; r++) {
      for (var c = 0; c < cols; c++) {
        blocks.push(
          <Block
            key={`gridBlock(${r},${c})`}
            size={scale}
            position={[c * scale - osX, 0, r * scale - osY]}
          />
        );
      }
    }
    return blocks;
  };

  return <group key={"blockgrid"}>{gridBlocks()}</group>;
};

export default BlockGrid;
