import React from 'react'

const Lights = () => {
  return (
    <>
    <ambientLight intensity={0.4} />
    <pointLight position={[0, 10, 0]} intensity={0.5}/>
    <pointLight position={[10, -10, 0]} intensity={0.2}/>
    </>
  )
}

export default Lights
