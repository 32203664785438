import React, { Component } from "react";
import { Canvas } from "react-three-fiber";
import Gateway from "../Gateway/Gateway";
import GameContent from "./GameContent";
import DebugInterface from "./Interface/Debug/DebugInterface";

const canvasStyle = {
  // TODO: use a skybox instaed of gradient?
  background: `radial-gradient(
    circle farthest-corner at center center,
    #1D2021,
    #000000
  ) no-repeat`,
  position: "absolute",
  width: "100%",
  height: "100%",
};

const closest = (intersects, state) => {
  // TODO: for obj in intersects, check obj.userData.captureRay
  // console.log(intersects.slice(0, 1));
  return intersects.slice(0, 1);
};

class Game extends Component {
  constructor(props) {
    super(props);

    this.server = this.props.server;

    this.state = {};

    this.handle_message = this.handle_message.bind(this);
    this.connect = this.connect.bind(this);
    this.disconnect = this.disconnect.bind(this);
  }

  connect(server) {
    this.gateway = new Gateway(this.server); // TODO: put actual url here.
    this.gateway.push_handler(this.handle_message);
    this.gateway.start();
  }

  disconnect() {
    this.gateway.stop();
    console.log("Connection closed by Game.");
  }

  componentDidMount() {
    if (!this.props.noconnect) {
      this.connect(this.server);
    }
  }

  handle_message(msg) {
    let data = JSON.parse(msg);
    let { code, payload } = data;

    switch (code) {
      case "echo":
        this.gateway.send({
          code: "echo",
          payload: payload,
        });
        break;
      default:
        console.error(`Got unknown message code ${code}`);
        break;
    }
  }

  render() {
    return (
      <>
        {/* <UserInterface /> */}
        <DebugInterface connect={this.connect} disconnect={this.disconnect} />
        <Canvas
          style={canvasStyle}
          raycaster={{ filter: closest }}
          onCreated={({ gl }) => (gl.shadowMap.enabled = true)}
        >
          <GameContent state={this.state} />
        </Canvas>
      </>
    );
  }
}

export default Game;
