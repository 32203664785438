class Gateway {
  constructor(url) {
    this.url = url;
    this.connected = false;
    this.restart_auto = true;
    this.callbacks = [];
  }

  start() {
    this.connection = new WebSocket(this.url);

    this.connection.onopen = this.on_open.bind(this);
    this.connection.onclose = this.on_close.bind(this);
    this.connection.onerror = this.on_error.bind(this);
    this.connection.onmessage = this.on_message.bind(this);

    console.log('Gateway connecting...');
  }

  stop() {
    this.restart_auto = false;
    this.connected = true;
    console.log('Gateway closing...');
  }

  on_open() {
    this.connected = true;
    console.log("Gateway connected.");
  }

  on_close() {
    this.connected = false;

    console.log('Gateway closed.');

    if (!this.restart_auto) {
        this.restart_auto = true;
        return;
    }

    setTimeout(() => {
        console.log('Gateway reconnecting...');
        this.start();
    }, 3000);
  }

  on_message(message) {
    for (const cb of this.callbacks) {
        cb(message.data);
    }
  }

  on_error(error) {
    console.log(error);
  }

  send(data) {
    if (!this.connected) {
        return;
    }
    this.connection.send(JSON.stringify(data));
  }

  push_handler(callback) {
    this.callbacks.push(callback);
  }
}

export default Gateway